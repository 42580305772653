import { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { DataGrid } from '@mui/x-data-grid';
import PageWrapper from '../../layout/PageWrapper';
import Nav from '../../components/Nav';
import { useAuth } from '../../components/Authentication/AuthProvider';
import Spinner from '../../components/Spinner';
import HeaderBar from '../../components/HeaderBar';
import IconButton from '../../components/IconButton';
import Modal from '../../components/Modal';
import Dropzone from '../../components/Dropzone';
import Alert from '../../components/Alert';
import { COLORS } from '../../utils/constants/colors';
import { saveAs } from 'file-saver';
import {
  getAllFirmwares,
  uploadFirmware,
  deleteFirmware,
} from '../../api/firmwareAPI';
import InputField from '../../components/InputField';
import { Download } from '@mui/icons-material';

const DropzoneGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const CenteredActions = styled.div`
  padding-left: 1rem;
`;

const Firmwares = () => {
  const [firmwares, setFirmwares] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedFirmware, setSelectedFirmware] = useState(null);
  const [selectedRows, setSelectedRows] = useState(false);
  const [newFirmwares, setNewFirmwares] = useState([]);
  const [errorMessage, setErrorMessage] = useState(null);
  const [notificationMessage, setNotificationMessage] = useState('');
  const { token } = useAuth();

  //MODAL VISIBILITY
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseConfirmation = () => {
    setIsConfirmationOpen(false);
  };

  const handleChangeNotificationMessage = (e) => {
    setNotificationMessage(e.target.value);
  };

  // GET ALL FIRMWARES
  const handleGetAllFirmwares = useCallback(async () => {
    setIsLoading(true);
    const arrayFirmwares = await getAllFirmwares(token);
    setFirmwares(arrayFirmwares);
    setIsLoading(false);
  }, [token]);

  useEffect(() => {
    handleGetAllFirmwares();
  }, [handleGetAllFirmwares]);

  useEffect(() => {
    setTimeout(() => {
      setErrorMessage(null);
    }, 5000);
  }, [errorMessage]);

  // UPDATE COEFFICIENT
  const handleUploadFirmware = async () => {
    setIsLoading(true);
    for (let newFirmware of newFirmwares) {
      const response = await uploadFirmware(newFirmware, token);
      if (response.status === 200) {
        handleCloseModal();
        handleGetAllFirmwares();
        setIsLoading(false);
      } else {
        setErrorMessage('Such version already exists');
        setIsLoading(false);
      }
    }
  };

  // DELETE Firmware
  const handleDeleteFirmware = async (id) => {
    setIsLoading(true);
    await deleteFirmware(id, token);
    handleGetAllFirmwares();
    setIsLoading(false);
  };

  const handleConfirmDelete = () => {
    handleDeleteFirmware(selectedFirmware);
    setIsConfirmationOpen(false);
  };

  // DELETE MULTIPLE FIRMWARES
  const handleDeleteMultipleFirmwares = async () => {
    setIsLoading(true);
    for (let selectedRow of selectedRows) {
      await handleDeleteFirmware(selectedRow);
    }
    handleGetAllFirmwares();
    setIsLoading(false);
  };

  const handleDeleteMultiple = () => {
    setIsConfirmationOpen(true);
  };

  const handleConfirmDeleteMultiple = () => {
    handleDeleteMultipleFirmwares();
    setIsConfirmationOpen(false);
  };

  // TABLE
  const columns = [
    { field: 'id', headerName: 'ID', minWidth: 50, maxWidth: 50, flex: 1 },
    {
      field: 'version',
      headerName: 'Version',
      minWidth: 150,
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'file',
      headerName: 'File',
      minWidth: 150,
      maxWidth: 250,
      flex: 1,
      renderCell: (rowData) => (
        <DownloadButton rowData={rowData}>Download</DownloadButton>
      ),
    },
    {
      field: 'uploadTime',
      headerName: 'Upload Time',
      minWidth: 150,
      maxWidth: 250,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      minWidth: 100,
      maxWidth: 100,
      flex: 1,
      renderCell: (rowData) => (
        <DeleteButton rowData={rowData}>Delete</DeleteButton>
      ),
    },
  ];

  // TABLE ACTIONS
  const DeleteButton = ({ rowData }) => {
    const handleDelete = () => {
      setIsConfirmationOpen(true);
      setSelectedFirmware(rowData?.id);
    };

    return !selectedRows.length ? (
      <CenteredActions>
        <IconButton onClick={handleDelete}>
          <DeleteOutlineIcon
            style={{ height: '20px', color: `${COLORS.red}` }}
          />
        </IconButton>
      </CenteredActions>
    ) : null;
  };

  const DownloadButton = ({ rowData }) => {
    const handleDownload = async () => {
      saveAs(rowData.row.url, rowData.row.file);
    };

    return !selectedRows.length ? (
      <CenteredActions style={{flex:1, display: 'flex', justifyItems: 'center'}}>
        <div style={{marginRight: '10px'}}>{rowData.row.file}</div>
        <IconButton onClick={handleDownload}>
          <Download
            style={{ height: '20px', color: `${COLORS.green}` }}
          />
        </IconButton>
      </CenteredActions>
    ) : null;
  };

  return (
    <>
      <Nav />
      <PageWrapper>
        <HeaderBar
          title='Firmwares'
          onOpenModal={handleOpenModal}
          handleDeleteMultiple={handleDeleteMultiple}
          disableDelete={selectedRows.length}
        />
        <div style={{ height: '70vh', width: '100%' }}>
          <DataGrid
            rows={firmwares || []}
            columns={columns}
            checkboxSelection
            disableSelectionOnClick
            onSelectionModelChange={(rowsID) => {
              setSelectedRows(rowsID);
            }}
            sx={{ color: `${COLORS.darkGray}` }}
          />
        </div>
      </PageWrapper>
      {isLoading && <Spinner />}
      <Modal
        title='Upload Firmware'
        isModalOpen={isModalOpen}
        onOpenModal={handleOpenModal}
        onCloseModal={handleCloseModal}
        onSave={handleUploadFirmware}
        disableSave={!newFirmwares.length || !notificationMessage}
      >
        <DropzoneGrid>
          <Dropzone
            setNewFirmwares={setNewFirmwares}
            fileExtension={['.cyacd']}
          />
          <InputField
            label='Notification message:'
            value={notificationMessage}
            onChange={handleChangeNotificationMessage}
            placeholder='Example: New Firmware Uploaded'
            required
          />
        </DropzoneGrid>
        {errorMessage && (
          <div style={{ marginTop: '2rem' }}>
            <Alert error>{errorMessage}</Alert>
          </div>
        )}
      </Modal>
      <Modal
        title='Confirmation'
        isModalOpen={isConfirmationOpen}
        saveText={selectedRows ? 'Delete Multiple' : 'Delete'}
        onSave={
          selectedRows.length
            ? handleConfirmDeleteMultiple
            : handleConfirmDelete
        }
        onCloseModal={handleCloseConfirmation}
        disableSave={false}
      >
        {selectedRows.length
          ? 'Are you sure you want to delete these firmwares?'
          : 'Are you sure you want to delete this firmware?'}
      </Modal>
    </>
  );
};

export default Firmwares;
